

.active-style {
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

.normal-style {
    color: #efefef;
    padding: 20px 15px;
    text-decoration: none;
    display: flex;
    justify-content: left;
    /*min-width: 170px;*/
    /*width: 100%;*/
    /*z-index: 1000;*/
    /*border-right: 2px solid #1e4271;*/
}

.tile {
    color:black !important;
    border-style: none;
}

.label-style {
    padding-left: 15px;
    text-transform: uppercase;
    font-weight: 500;
}

.hover-style {
    text-decoration: underline;
}
