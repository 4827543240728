
/* Variables */
.appbar{
    width: 100%;
    grid-column: 1/20;
    background-color: rgb(58, 64, 67);
}

.toolbar{
    min-height: 1px;
}
.navbar-nav span[role=button] {
    /*padding: 15px;*/
    display: inline-block;
    line-height: 2px;
}
.nav-navlink {
    position: relative;
}
.nav-navlink.no-active {
    background: rgb(58, 64, 67);
}
.nav-navlink:hover .nav-submenu {
    display: block;

}
.nav-navlink .nav-submenu {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    top: 100%;
    background-color:  rgb(58, 64, 67);
    min-width: 170px;
    width: 100%;
    z-index: 1000;
    /*border-left: 2px solid #096b2a;*/
    /*border-right: 2px solid #096b2a;*/
    /*border-top: 2px solid #096b2a;*/
}
.nav-navlink .nav-submenu .nav-subnavlink {
    display: block;
    width: 100%;
    /*border-bottom: 2px solid #096b2a;*/
    font-weight: 500;
}
.nav-navlink .nav-submenu .nav-subnavlink.active {
    background-color: rgba(4,115,58,1);
}

.mobile-nav{
    display: none
}

@media screen and (max-width: 1080px) {
    .toolbar {
        display: none !important;
    }

    .navbar-nav span[role=button] {
        display: none;
    }

    .nav-navlink {
        display: none;
    }

    .nav-navlink .nav-submenu {
        display: none;
    }

    .nav-navlink .nav-submenu .nav-subnavlink {
        display: none;
    }

    .mobile-nav {
        display: grid !important;
        grid-column: 10/20;
        align-items: center;
        align-content: center;
    }

    .main-header-drop-down-select {
        grid-column: 8/16;
        align-self: center;
        display: grid;
        width: 150px;
        height: 20px;
        background-color: rgb(58, 64, 67);
    }
}

/*.appbarStyle {*/
/*    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(4,115,58,1) 100%);*/
/*}*/